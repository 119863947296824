@use 'sass:map';
@use '@angular/material' as mat;
@import './app/color-palettes/navy-blue-gold.scss';
// @import './app/nt-palette-2.scss';
// @import './app/nt-palette-3.scss';
// @import './app/nt-palette-4.scss';
// @import './app/nt-palette-5.scss';

$color-config:    mat.get-color-config($theme);
$primary-palette: map.get($color-config, 'primary');
$accent-palette:  map.get($color-config, 'accent');
$warn-palette:    map.get($color-config, 'warn');
$is-dark-theme:   map.get($color-config, 'is-dark');

.bg-nt-primary {
    background-color: mat.get-color-from-palette($primary-palette);
}

.bg-nt-accent {
    background-color: mat.get-color-from-palette($accent-palette);
}

.bg-nt-warn {
    background-color: mat.get-color-from-palette($warn-palette);
}
.bg-nt-transparent {
    background-color: transparent
}

.nt-primary {
    color: mat.get-color-from-palette($primary-palette);
}

.nt-accent {
    color: mat.get-color-from-palette($accent-palette);
}

.nt-warn {
    color: mat.get-color-from-palette($warn-palette);
}
// Style for main title, landing page, title pages, etc...
.nt-title {
    font-family: Inter;
    font-size: 38px;
    font-weight: 800;
    line-height: 1.2;
    color: mat.get-color-from-palette($primary-palette);
}
// Style for title landing pages, title pages with Accent color to hightlight content
.nt-title-accent {
    font-family: Inter;
    font-size: 38px;
    font-weight: 800;
    line-height: 1.2;
    color: mat.get-color-from-palette($accent-palette);
}
// Style for subtitle content
.nt-subtitle {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    color: mat.get-color-from-palette($primary-palette);
}
.nt-subsubtitle {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    color: mat.get-color-from-palette($primary-palette);
}
// Style for muted text under titles, smaller and light colors
.nt-title-muted {
    font-family: Inter;
    font-size: 20px;
    color: rgb(107, 114, 128);
    line-height: 1.4;
}
// Style for content text, paragraphs, data
.nt-content {
    font-family: Inter;
    font-size: 16px;
    color: mat.get-color-from-palette($primary-palette);
    // line-height: 1.2;
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: url(https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh50XSwaPGQ3q5d0N7w.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
// /* latin */
// @font-face {
//     font-family: 'Lato';
//     font-style: normal;
//     font-weight: 900;
//     src: url(https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh50XSwiPGQ3q5d0.woff2) format('woff2');
//     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* latin */
// @font-face {
//     font-family: 'Mada';
//     font-style: normal;
//     font-weight: 400;
//     src: url(https://fonts.gstatic.com/s/mada/v11/7Auwp_0qnzeSfTLGLQjUwkQ.woff2) format('woff2');
//     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* latin-ext */
// @font-face {
//     font-family: 'Open Sans';
//     font-style: italic;
//     font-weight: 400;
//     src: url(https://fonts.gstatic.com/s/opensans/v20/mem6YaGs126MiZpBA-UFUK0Xdc1GAK6bt6o.woff2) format('woff2');
//     unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//     font-family: 'Open Sans';
//     font-style: italic;
//     font-weight: 400;
//     src: url(https://fonts.gstatic.com/s/opensans/v20/mem6YaGs126MiZpBA-UFUK0Zdc1GAK6b.woff2) format('woff2');
//     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// @font-face {
//     font-family: 'Open Sans';
//     font-style: normal;
//     font-weight: 400;
//     src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-UFW50bf8pkAp6a.woff2) format('woff2');
//     unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//     font-family: 'Open Sans';
//     font-style: normal;
//     font-weight: 400;
//     src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2) format('woff2');
//     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// // 
// /* latin-ext */
// @font-face {
//     font-family: 'Open Sans';
//     font-style: normal;
//     font-weight: 600;
//     src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirkOXOhpKKSTj5PW.woff2) format('woff2');
//     unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//     font-family: 'Open Sans';
//     font-style: normal;
//     font-weight: 600;
//     src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2) format('woff2');
//     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }


body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

b, strong {
    font-weight: bold;
}

i, em {
    font-style: italic;
}

body {
    font-family: Helvetica, Arial, sans-serif;
}

sup {
    vertical-align: super;
    font-size: smaller;
}

sub {
    vertical-align: sub;
    font-size: smaller;
}

ul, ol {
    display: block;
    padding-left: 40px;
}

ul {
    list-style-type: disc;
}

ol {
    list-style-type: decimal;
}

a {
    color: inherit;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
    color: inherit;
}
a:visited {
    color: inherit;
    text-decoration: none;
}

.sol-icon {
    height: 48px;
    width: 48px; 
    left: 20px;
    background-color: mat.get-color-from-palette($primary-palette);
    border-radius: 5px;
    float: left;
}

.sol-inner-icon {
    position: absolute;
    margin-top: 12px;
    margin-left: 12px;
    height: 24px;
    width: 24px;
    text-align: center;
    color: rgb(255, 255, 255); 
    font-size: 24px;
}

.call-to-action-dark {
    height: 52px; 
    width: 157px; 
    cursor: inherit; 
    background: none mat.get-color-from-palette($primary-palette);
    border: none; 
    text-align: center; 
    font-family: Inter; 
    font-size: 16px; 
    font-weight: 500;
    color: rgb(255, 255, 255);
    line-height: 1; 
    border-radius: 5px; 
    transition: box-shadow 200ms ease 0s, background 200ms ease 0s;
}

.cta-big-text {
    margin-top: 18px;
    margin-bottom: 18px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
}

.call-to-action-light {
    display: block;
    height: 52px;
    width: 157px; 
    cursor: inherit; 
    background: none rgb(229, 237, 255); 
    border: none; 
    text-align: center; 
    font-family: Inter; 
    font-size: 16px;
    font-weight: 500; 
    color: mat.get-color-from-palette($primary-palette); 
    line-height: 1; 
    border-radius: 5px; 
    transition: box-shadow 200ms ease 0s, background 200ms ease 0s;
}

.favorite-icon {
    color: rgb(255, 238, 0);
}

.clickable {
    cursor: pointer;
}
